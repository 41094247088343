import * as actionTypes from '../actions/actionTypes';

export const assetValuationsInitialState = {
  valuations: [],
  loading: false,
  loaded: false,
  error: null,
  details: {
    details: {},
    activityFeed: [],
    loading: false,
    loaded: false,
    error: null
  },
  valuers: {
    valuers: [],
    loading: false,
    loaded: false,
    error: null
  },
};

const reducer = (state = assetValuationsInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VALUATIONS_START:
      return {
        ...state,
        loading: true,
        valuations: [],
        loaded: false
      };
    case actionTypes.GET_VALUATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        valuations: action.valuations
      };
    case actionTypes.GET_VALUATIONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.GET_VALUATIONS_DETAILS_START:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true
        }
      };
    case actionTypes.GET_VALUATIONS_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          loaded: true,
          details: action.details
        }
      };
    case actionTypes.GET_VALUATIONS_DETAILS_FAIL:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.SEARCH_VALUER_START:
      return {
        ...state,
        valuers: {
          loading: true
        }
      };
    case actionTypes.SEARCH_VALUER_SUCCESS:
      return {
        ...state,
        valuers: {
          ...state.valuers,
          loading: false,
          loaded: true,
          valuers: action.details.valuersList
        }
      };
    case actionTypes.SEARCH_VALUER_FAIL:
      return {
        ...state,
        valuers: {
          ...state.valuers,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.RESET_VALUATIONS_STATE: {
      return assetValuationsInitialState;
    }

    default:
      return state;
  }
};

export default reducer;
