// source: external/document.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var external_filters_gorm_pb = require('./filters_gorm_pb.js');
goog.object.extend(proto, external_filters_gorm_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.external.CreateDocumentRequest', null, global);
goog.exportSymbol('proto.external.CreateDocumentRequest.CreateDocumentMessage', null, global);
goog.exportSymbol('proto.external.DeleteDocumentRequest', null, global);
goog.exportSymbol('proto.external.DocumentCategory', null, global);
goog.exportSymbol('proto.external.ListDocumentsRequest', null, global);
goog.exportSymbol('proto.external.ListDocumentsResponse', null, global);
goog.exportSymbol('proto.external.ListDocumentsResponse.Document', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ListDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.ListDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ListDocumentsRequest.displayName = 'proto.external.ListDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ListDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ListDocumentsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.ListDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ListDocumentsResponse.displayName = 'proto.external.ListDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ListDocumentsResponse.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.ListDocumentsResponse.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ListDocumentsResponse.Document.displayName = 'proto.external.ListDocumentsResponse.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.DeleteDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.DeleteDocumentRequest.repeatedFields_, null);
};
goog.inherits(proto.external.DeleteDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.DeleteDocumentRequest.displayName = 'proto.external.DeleteDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.CreateDocumentRequest.repeatedFields_, null);
};
goog.inherits(proto.external.CreateDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateDocumentRequest.displayName = 'proto.external.CreateDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.CreateDocumentRequest.CreateDocumentMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.CreateDocumentRequest.CreateDocumentMessage.displayName = 'proto.external.CreateDocumentRequest.CreateDocumentMessage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ListDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ListDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ListDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filters: (f = msg.getFilters()) && external_filters_gorm_pb.Filters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ListDocumentsRequest}
 */
proto.external.ListDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ListDocumentsRequest;
  return proto.external.ListDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ListDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ListDocumentsRequest}
 */
proto.external.ListDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new external_filters_gorm_pb.Filters;
      reader.readMessage(value,external_filters_gorm_pb.Filters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ListDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ListDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ListDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      external_filters_gorm_pb.Filters.serializeBinaryToWriter
    );
  }
};


/**
 * optional Filters filters = 1;
 * @return {?proto.external.Filters}
 */
proto.external.ListDocumentsRequest.prototype.getFilters = function() {
  return /** @type{?proto.external.Filters} */ (
    jspb.Message.getWrapperField(this, external_filters_gorm_pb.Filters, 1));
};


/**
 * @param {?proto.external.Filters|undefined} value
 * @return {!proto.external.ListDocumentsRequest} returns this
*/
proto.external.ListDocumentsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.ListDocumentsRequest} returns this
 */
proto.external.ListDocumentsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ListDocumentsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ListDocumentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ListDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ListDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ListDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.external.ListDocumentsResponse.Document.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ListDocumentsResponse}
 */
proto.external.ListDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ListDocumentsResponse;
  return proto.external.ListDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ListDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ListDocumentsResponse}
 */
proto.external.ListDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.ListDocumentsResponse.Document;
      reader.readMessage(value,proto.external.ListDocumentsResponse.Document.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ListDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ListDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ListDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.ListDocumentsResponse.Document.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ListDocumentsResponse.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ListDocumentsResponse.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ListDocumentsResponse.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListDocumentsResponse.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filetype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    documenttype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assetid: (f = msg.getAssetid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tenantid: (f = msg.getTenantid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    category: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ListDocumentsResponse.Document}
 */
proto.external.ListDocumentsResponse.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ListDocumentsResponse.Document;
  return proto.external.ListDocumentsResponse.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ListDocumentsResponse.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ListDocumentsResponse.Document}
 */
proto.external.ListDocumentsResponse.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiletype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumenttype(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAssetid(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTenantid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {!proto.external.DocumentCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ListDocumentsResponse.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ListDocumentsResponse.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ListDocumentsResponse.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListDocumentsResponse.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFiletype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDocumenttype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssetid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTenantid();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.ListDocumentsResponse.Document.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
 */
proto.external.ListDocumentsResponse.Document.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fileType = 2;
 * @return {string}
 */
proto.external.ListDocumentsResponse.Document.prototype.getFiletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
 */
proto.external.ListDocumentsResponse.Document.prototype.setFiletype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.external.ListDocumentsResponse.Document.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
 */
proto.external.ListDocumentsResponse.Document.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string documentType = 4;
 * @return {string}
 */
proto.external.ListDocumentsResponse.Document.prototype.getDocumenttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
 */
proto.external.ListDocumentsResponse.Document.prototype.setDocumenttype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue assetID = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.ListDocumentsResponse.Document.prototype.getAssetid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
*/
proto.external.ListDocumentsResponse.Document.prototype.setAssetid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
 */
proto.external.ListDocumentsResponse.Document.prototype.clearAssetid = function() {
  return this.setAssetid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ListDocumentsResponse.Document.prototype.hasAssetid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue tenantID = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.ListDocumentsResponse.Document.prototype.getTenantid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
*/
proto.external.ListDocumentsResponse.Document.prototype.setTenantid = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
 */
proto.external.ListDocumentsResponse.Document.prototype.clearTenantid = function() {
  return this.setTenantid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ListDocumentsResponse.Document.prototype.hasTenantid = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.external.ListDocumentsResponse.Document.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
 */
proto.external.ListDocumentsResponse.Document.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional DocumentCategory category = 8;
 * @return {!proto.external.DocumentCategory}
 */
proto.external.ListDocumentsResponse.Document.prototype.getCategory = function() {
  return /** @type {!proto.external.DocumentCategory} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.external.DocumentCategory} value
 * @return {!proto.external.ListDocumentsResponse.Document} returns this
 */
proto.external.ListDocumentsResponse.Document.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated Document documents = 1;
 * @return {!Array<!proto.external.ListDocumentsResponse.Document>}
 */
proto.external.ListDocumentsResponse.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.external.ListDocumentsResponse.Document>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.ListDocumentsResponse.Document, 1));
};


/**
 * @param {!Array<!proto.external.ListDocumentsResponse.Document>} value
 * @return {!proto.external.ListDocumentsResponse} returns this
*/
proto.external.ListDocumentsResponse.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.ListDocumentsResponse.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.ListDocumentsResponse.Document}
 */
proto.external.ListDocumentsResponse.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.ListDocumentsResponse.Document, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ListDocumentsResponse} returns this
 */
proto.external.ListDocumentsResponse.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.DeleteDocumentRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.DeleteDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.DeleteDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.DeleteDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.DeleteDocumentRequest}
 */
proto.external.DeleteDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.DeleteDocumentRequest;
  return proto.external.DeleteDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.DeleteDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.DeleteDocumentRequest}
 */
proto.external.DeleteDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.DeleteDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.DeleteDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.DeleteDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.DeleteDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string id = 1;
 * @return {!Array<string>}
 */
proto.external.DeleteDocumentRequest.prototype.getIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.DeleteDocumentRequest} returns this
 */
proto.external.DeleteDocumentRequest.prototype.setIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.DeleteDocumentRequest} returns this
 */
proto.external.DeleteDocumentRequest.prototype.addId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.DeleteDocumentRequest} returns this
 */
proto.external.DeleteDocumentRequest.prototype.clearIdList = function() {
  return this.setIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.CreateDocumentRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.CreateDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.CreateDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.CreateDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.external.CreateDocumentRequest.CreateDocumentMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateDocumentRequest}
 */
proto.external.CreateDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateDocumentRequest;
  return proto.external.CreateDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateDocumentRequest}
 */
proto.external.CreateDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.CreateDocumentRequest.CreateDocumentMessage;
      reader.readMessage(value,proto.external.CreateDocumentRequest.CreateDocumentMessage.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.CreateDocumentRequest.CreateDocumentMessage.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.external.CreateDocumentRequest.CreateDocumentMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.CreateDocumentRequest.CreateDocumentMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: (f = msg.getAssetid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tenantid: (f = msg.getTenantid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    documenttype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    filetype: jspb.Message.getFieldWithDefault(msg, 4, ""),
    path: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    category: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.CreateDocumentRequest.CreateDocumentMessage;
  return proto.external.CreateDocumentRequest.CreateDocumentMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.CreateDocumentRequest.CreateDocumentMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAssetid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTenantid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumenttype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFiletype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {!proto.external.DocumentCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.CreateDocumentRequest.CreateDocumentMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.CreateDocumentRequest.CreateDocumentMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTenantid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumenttype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFiletype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue assetID = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.getAssetid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage} returns this
*/
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.setAssetid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage} returns this
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.clearAssetid = function() {
  return this.setAssetid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.hasAssetid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue tenantID = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.getTenantid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage} returns this
*/
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.setTenantid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage} returns this
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.clearTenantid = function() {
  return this.setTenantid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.hasTenantid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string documentType = 3;
 * @return {string}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.getDocumenttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage} returns this
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.setDocumenttype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fileType = 4;
 * @return {string}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.getFiletype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage} returns this
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.setFiletype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string path = 5;
 * @return {string}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage} returns this
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage} returns this
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional DocumentCategory category = 7;
 * @return {!proto.external.DocumentCategory}
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.getCategory = function() {
  return /** @type {!proto.external.DocumentCategory} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.external.DocumentCategory} value
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage} returns this
 */
proto.external.CreateDocumentRequest.CreateDocumentMessage.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated CreateDocumentMessage documents = 1;
 * @return {!Array<!proto.external.CreateDocumentRequest.CreateDocumentMessage>}
 */
proto.external.CreateDocumentRequest.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.external.CreateDocumentRequest.CreateDocumentMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.CreateDocumentRequest.CreateDocumentMessage, 1));
};


/**
 * @param {!Array<!proto.external.CreateDocumentRequest.CreateDocumentMessage>} value
 * @return {!proto.external.CreateDocumentRequest} returns this
*/
proto.external.CreateDocumentRequest.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.CreateDocumentRequest.CreateDocumentMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.CreateDocumentRequest.CreateDocumentMessage}
 */
proto.external.CreateDocumentRequest.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.CreateDocumentRequest.CreateDocumentMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.CreateDocumentRequest} returns this
 */
proto.external.CreateDocumentRequest.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * @enum {number}
 */
proto.external.DocumentCategory = {
  DOCUMENT_TYPE_UNSPECIFIED: 0,
  LEGAL_DOCUMENT: 1,
  TECHNICAL_DOCUMENT: 2,
  LEASE_AGREEMENTS_DOCUMENT: 3,
  VALUATIONS_DOCUMENT: 4,
  OTHER_DOCUMENT: 5
};

goog.object.extend(exports, proto.external);
