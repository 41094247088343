import React, {Suspense, useEffect} from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Skeleton} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import GraphCard from './GraphCard';
import PropTypes from "prop-types";
import * as actions from "../../store/actions";

const SkeletonComponent = (
    <Skeleton
        variant="rectangular"
        width="100%"
        animation="wave"
        height="100%"
        style={{borderRadius: '10px'}}
    />
);

const KPIs = ({portfolioIds, assetIds, period, year, firstAsset}) => {
    const {data} = useSelector((state) => state.home.chartData);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.getChartData({
            PortfolioID: portfolioIds ? portfolioIds : [],
            AssetID: assetIds ? assetIds : [],
            Year: period === 'month' ? [year] : new Array(5).fill().map((_, i) => year + i)
        }, period, period === 'year' ? {year: year, length: 5} : null));
    }, [portfolioIds, assetIds, year, period]);

    const dataMap = [
        {
            title: "Income",
            charts: [
                {
                    name: 'Base Rent',
                    alias: 'base-rent',
                    link: '/BaseRent/Details',
                    data: data?.BaseRent
                },
                {
                    name: 'Service Charge',
                    alias: 'service-charge',
                    link: '/ServiceCharge/Details',
                    data: data?.ServiceCharge
                },
                {
                    name: 'Other Income',
                    alias: 'other-income',
                    link: '/OtherIncome/Details',
                    data: data?.OtherIncome
                },
                {
                    name: 'Cumulative Rent',
                    alias: 'cumulative-rent',
                    link: '/CumulativeRent/Details',
                    data: data?.CumulativeRent,
                    notCurrency: true
                },
                {
                    name: 'Turnover Rent',
                    alias: 'turnover-rent',
                    link: '/TurnoverRent/Details',
                    data: data?.TurnoverRent
                }
            ]
        },
        {
            title: "Expenses",
            charts: [
                {
                    name: 'Opex',
                    alias: 'opex',
                    link: '/Opex/Details',
                    data: data?.Opex
                },
                {
                    name: 'Capex',
                    alias: 'capex',
                    link: '/Capex/Details',
                    data: data?.Capex
                },
                {
                    name: 'Leasing Fee',
                    alias: 'leasing-fee',
                    link: '/LeasingFee/Details',
                    data: data?.LeasingFee
                },
                {
                    name: 'Fit Out',
                    alias: 'fit-out',
                    link: '/FitOut/Details',
                    data: data?.FitOut
                },
                {
                    name: 'Other Expenses',
                    alias: 'other-expenses',
                    link: '/OtherExpenses/Details',
                    data: data?.OtherExpenses
                }
            ]
        },
        {
            title: "KPI's",
            charts: [
                {
                    name: 'NOI',
                    alias: 'noi',
                    link: '/NOI/Details',
                    data: data?.NOI
                },
                {
                    name: 'Leases',
                    alias: 'tenants',
                    link: null,
                    data: data?.Tenants,
                    notCurrency: true
                },
                {
                    name: 'Occupancy',
                    alias: 'occupancy',
                    link: null,
                    data: data?.Occupancy,
                    notCurrency: true
                },
                {
                    name: 'MLA',
                    alias: 'mla',
                    link: '/MLA/Details',
                    data: data?.MLA,
                    notCurrency: true
                },
            ]
        }
    ]

    return (
        <>
            {dataMap.map((item) => (
                <React.Fragment key={item.title}>
                    <Typography variant="h4" component="h2" gutterBottom sx={{mb: 2, mt: 4}}>
                        {item.title}
                    </Typography>
                    <Grid container spacing={3}>
                        {item.charts.map((item) => {
                            let series = []
                            if (item.data?.actualforecastsList?.length > 0) {
                                series = [
                                    {
                                        name: `F`,
                                        data: item.data?.forecastsList || []
                                    },
                                    {
                                        name: `A`,
                                        data: item.data?.actualforecastsList || []
                                    }
                                ]
                            } else {
                                series = [
                                    {
                                        name: item.name,
                                        data: item.data?.forecastsList || []
                                    }
                                ]
                            }

                            return(
                                <Grid item xs={12} sm={6} md={4} key={item.alias}>
                                    <Suspense fallback={SkeletonComponent}>
                                        <GraphCard
                                            dataCy={item.alias}
                                            to={item.link === null ? null : `${item.link}/${firstAsset}`}
                                            from={window.location.search}
                                            totalValue={item.notCurrency ? null : item.data?.currentmonthrent}
                                            prevTotalValue={item.notCurrency ? null : item.data?.lastmonthrent}
                                            series={series}
                                            name={item.name}
                                            period={period}
                                            year={year}
                                        />
                                    </Suspense>
                                </Grid>
                            )})}
                    </Grid>
                </React.Fragment>
            ))}
        </>
    )
}

export default KPIs;

KPIs.defaultProps = {
    portfolioIds: [],
    assetIds: [],
    period: 'month',
    year: new Date().getFullYear()
};

KPIs.propTypes = {
    portfolioIds: PropTypes.arrayOf(PropTypes.string),
    assetIds: PropTypes.arrayOf(PropTypes.string),
    period: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired
};
