import { metaData } from 'config/config';
import { formatISO } from 'date-fns';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';

import {
  GetBreadCrumbsRequest,
  GetTenantByPortfolioIDsRequest,
  GetTenantHeaderRequest
} from '../../api/aggregation_grpc_web_pb';
import {
  CreateTenantRequest,
  DeleteTenantRequest,
  GetTenantRequest,
  GetTenantsRequest,
  GetTotalRentByAssetAndYearRequest,
  UpdateTenantRequest
} from '../../api/tenant_grpc_web_pb';
import { capitalize } from '../../helpers/utils';
import { AggregationService } from '../services/aggregation';
import { ContractClauseService, TenantService } from '../services/tenant';

import * as actionTypes from './actionTypes';

const getTenantsStart = () => ({
  type: actionTypes.GET_TENANTS_START
});

const getTenantsSuccess = (tenants, bundleId) => ({
  type: actionTypes.GET_TENANTS_SUCCESS,
  tenants,
  bundleId
});

const getTenantsFail = (error) => ({
  type: actionTypes.GET_TENANTS_FAIL,
  error
});
export const updateTenant = (form) => async () => {
  const request = new UpdateTenantRequest();
  request.setId(form.id);
  request.setTenantcontractid(form.tenantcontractid);

  const companyname = new wrappers.StringValue();
  companyname.setValue(form.companyname);
  request.setCompanyname(companyname);

  const tradingname = new wrappers.StringValue();
  tradingname.setValue(form.tradingname);
  request.setTradingname(tradingname);

  const industrytype = new wrappers.StringValue();
  industrytype.setValue(form.industry);
  request.setIndustry(industrytype);

  const contractenddate = new wrappers.StringValue();
  const endDate = formatISO(new Date(form.contractenddate), { representation: 'date' });
  contractenddate.setValue(endDate);
  request.setContractenddate(contractenddate);

  const contractstartdate = new wrappers.StringValue();
  const startDate = formatISO(new Date(form.contractstartdate), { representation: 'date' });
  contractstartdate.setValue(startDate);
  request.setContractstartdate(contractstartdate);
  form.unitidsList.forEach((id) => {
    request.addUnitids(id);
  });

  const restore = new wrappers.BoolValue();
  restore.setValue(true);
  request.setRestore(restore);

  try {
    const result = await TenantService.updateTenant(request, metaData());
    const response = result.toObject();

    return response;
  } catch (error) {
    throw error.message;
  }
};

export const createTenant = (form, assetId) => async () => {
  const request = new CreateTenantRequest();
  request.setCompanyname(form.companyname);
  request.setTradingname(form.tradingname);
  request.setIndustry(capitalize(form.industry));
  request.setAssetid(assetId);

  const headOfficeAddress = new wrappers.StringValue();
  headOfficeAddress.setValue(form.headOfficeAddress);
  request.setHeadOfficeAddress(headOfficeAddress);

  const notes = new wrappers.StringValue();
  notes.setValue(form.notes);
  request.setNotes(notes);

  const mainContact = new wrappers.StringValue();
  mainContact.setValue(form.mainContact);
  request.setMainContact(mainContact);

  const position = new wrappers.StringValue();
  position.setValue(form.position);
  request.setPosition(position);

  const email = new wrappers.StringValue();
  email.setValue(form.email);
  request.setEmail(email);

  const phone = new wrappers.StringValue();
  phone.setValue(form.phone);
  request.setPhone(phone);

  const enddate = new wrappers.StringValue();
  const endDate = formatISO(new Date(form.contractenddate), { representation: 'date' });
  enddate.setValue(endDate);
  request.setEnddate(enddate);

  const startdate = new wrappers.StringValue();
  const startDate = formatISO(new Date(form.contractstartdate), { representation: 'date' });
  startdate.setValue(startDate);
  request.setStartdate(startdate);
  form.unitidsList.forEach((id) => {
    request.addUnitids(id);
  });

  try {
    const result = await TenantService.createTenant(request, metaData());
    const response = result.toObject();

    return response;
  } catch (error) {
    throw error.message;
  }
};

export const setTenantsBundle = (bundleId) => ({ type: actionTypes.SET_TENANTS_BUNDLE, bundleId });

export const getTenants =
  (portfolioIds, bundleId, assetIds, portfolioFilters) => async (dispatch) => {
    dispatch(getTenantsStart());

    if (portfolioFilters) {
      const request = new GetTenantByPortfolioIDsRequest();
      request.setPortfoliosList(portfolioIds || []);

      try {
        const result = await AggregationService.getTenantByPortfolioIDs(request, metaData());
        const response = result.toObject().tenantsList;
        dispatch(getTenantsSuccess(response, bundleId));

        return response;
      } catch (error) {
        dispatch(getTenantsFail(error.message));

        throw error.message;
      }
    }

    const request = new GetTenantsRequest();
    request.setAssetidsList(assetIds || []);

    try {
      const result = await TenantService.getTenants(request, metaData());
      const response = result.toObject().tenantsList;
      dispatch(getTenantsSuccess(response, bundleId));
      return response;
    } catch (error) {
      dispatch(getTenantsFail(error.message));

      throw error.message;
    }
  };

// Get Tenants Details
const getTenantDetailsStart = () => ({
  type: actionTypes.GET_TENANTS_DETAILS_START
});

const getTenantDetailsSuccess = (tenantDetails) => ({
  type: actionTypes.GET_TENANTS_DETAILS_SUCCESS,
  tenantDetails
});

const getTenantDetailsFail = (error) => ({
  type: actionTypes.GET_TENANTS_DETAILS_FAIL,
  error
});

export const getTenantDetails = (id) => async (dispatch) => {
  dispatch(getTenantDetailsStart());
  const request = new GetTenantRequest();
  request.setId(id);

  try {
    const result = await TenantService.getTenant(request, metaData());
    const response = result.toObject().tenant;
    dispatch(getTenantDetailsSuccess(response));

    return response;
  } catch (error) {
    dispatch(getTenantDetailsFail(error.message));

    throw error.message;
  }
};

export const deleteTenant = (id) => async () => {
  const request = new DeleteTenantRequest();
  request.setId(id);

  try {
    const result = await TenantService.deleteCTenant(request, metaData());
    const response = result.toObject();
    return response;
  } catch (error) {
    throw error.message;
  }
};

// Get Tenants Header
const getTenantHeaderStart = () => ({
  type: actionTypes.GET_TENANT_HEADER_START
});

const getTenantHeaderSuccess = (header) => ({
  type: actionTypes.GET_TENANT_HEADER_SUCCESS,
  header
});

const getTenantHeaderFail = (error) => ({
  type: actionTypes.GET_TENANT_HEADER_FAIL,
  error
});

export const getTenantHeader = (id) => async (dispatch) => {
  dispatch(getTenantHeaderStart());
  const request = new GetTenantHeaderRequest();
  request.setTenantid(id);

  try {
    const result = await AggregationService.getTenantHeader(request, metaData());
    const response = result.toObject();

    dispatch(getTenantHeaderSuccess(response));

    return response;
  } catch (error) {
    dispatch(getTenantHeaderFail(error.message));
    return error.message;
  }
};

// Get Tenants Breadcrumps
const getTenantCrumpsStart = () => ({
  type: actionTypes.GET_BREADCRUMPS_START
});

const getTenantCrumpsSuccess = (crumps) => ({
  type: actionTypes.GET_BREADCRUMPS_SUCCESS,
  crumps
});

const getTenantCrumpsFail = (error) => ({
  type: actionTypes.GET_BREADCRUMPS_FAIL,
  error
});

export const getTenantCrumps = (id) => async (dispatch) => {
  dispatch(getTenantCrumpsStart());
  const request = new GetBreadCrumbsRequest();
  request.setTenantid(id);

  try {
    const result = await AggregationService.getBreadCrumbs(request, metaData());
    const response = result.toObject();

    dispatch(getTenantCrumpsSuccess(response));

    return response;
  } catch (error) {
    dispatch(getTenantCrumpsFail(error.message));
    throw error.message;
  }
};

const getTotalRentByAssetAndYearStart = () => ({
  type: actionTypes.GET_TOTAL_RENT_BY_ASSET_AND_YEAR_START
});

const getTotalRentByAssetAndYearSuccess = (totalRentByAsset) => ({
  type: actionTypes.GET_TOTAL_RENT_BY_ASSET_AND_YEAR_SUCCESS,
  totalRentByAsset
});

const getTotalRentByAssetAndYearFail = (error) => ({
  type: actionTypes.GET_TOTAL_RENT_BY_ASSET_AND_YEAR_FAIL,
  error
});

export const getTotalRentByAssetAndYear = (assetIDs, year) => async (dispatch) => {
  dispatch(getTotalRentByAssetAndYearStart());

  const request = new GetTotalRentByAssetAndYearRequest();
  request.setAssetidList(assetIDs);
  request.setYear(year);
  await ContractClauseService.getTotalRentByAssetAndYear(request, metaData(), (err, response) => {
    if (err) {
      dispatch(getTotalRentByAssetAndYearFail(err));
    } else {
      dispatch(getTotalRentByAssetAndYearSuccess(response.toObject().calculationList));
    }
  });
};
