import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import makeStyles from '@mui/styles/makeStyles';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { openModal, closeModal } from 'components/Modal/modalReducer';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontFamily: theme.fonts.baseSerif,
    fontSize: 30,
    color: theme.palette.common.black,
    fontWeight: 500
  },
  subtitle: {
    color: theme.palette.text.grey,
    fontFamily: theme.fonts.openSans,
    fontSize: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  title: {
    fontFamily: theme.fonts.openSans,
    fontSize: 12,
    color: theme.palette.text.grey
  },
  boxResponsive: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontFamily: theme.fonts.base,
    marginRight: theme.spacing(2),
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  labelRoot: {
    border: '1px solid #D9D9D9',
    borderRadius: 25,
    padding: theme.spacing(0.3, 2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0)
    }
  }
}));

const LeasingManagerHeader = () => {
  const classes = useStyles();
  const [inPlace, setInplace] = React.useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const { contract: contracts, loaded: contractLoaded } = useSelector(
    (state) => state.leasingManager
  );
  const { data } = useSelector((state) => state.tenants.tenantDetails);

  useEffect(() => {
    if (!contractLoaded) {
      return;
    }
    setInplace(contracts[0]?.inplace || false);
  }, [contracts]);

  const changeInPlace = () => {
    dispatch(actions.UpdateLeasingContract(contracts[0]?.id, !inPlace))
      .then(() => {
        setInplace(!inPlace);

        dispatch(actions.getContractById(router.query.id)).then((res) => {
          dispatch(actions.getTenantsByAssetId(data.assetid));

          if (res.contractsList.length > 0) {
            toast.success(
              inPlace ? 'Contract switched to out-of-place!' : 'Contract switched to in-place!'
            );
          }
        });
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleConfirm = () => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          title: 'Change In-Place',
          content: "Are you sure you want to Change this contract's status?",
          handleConfirm: () => {
            changeInPlace();
            dispatch(closeModal());
          }
        }
      })
    );
  };

  return (
    (<Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.boxResponsive}
    >
      <Box display="flex" alignItems="center" className={classes.boxResponsive}>
        {contracts.length > 0 && (
          <FormControl variant="standard" component="fieldset">
            <FormGroup aria-label="position" row>
              <Tooltip title="Change contract status" placement="top">
                <FormControlLabel
                  value="start"
                  control={<Switch color="primary" checked={inPlace} onChange={handleConfirm} />}
                  label="IN-PLACE"
                  labelPlacement="start"
                  classes={{ label: classes.label, root: classes.labelRoot }}
                />
              </Tooltip>
            </FormGroup>
          </FormControl>
        )}
      </Box>
    </Box>)
  );
};

export default LeasingManagerHeader;
