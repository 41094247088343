import {
    CreateRequest,
    DeleteRequest,
    GetSubCategoriesRequest,
    ListActualsRequest,
    UpdateRequest
} from 'api/actual_grpc_web_pb';
import {ScanRequest} from 'api/antivirus_notification_service_pb';
import {IngestActualsRequest} from 'api/data_ingestion_grpc_web_pb';
import {metaData} from 'config/config';
import {formatISO} from 'date-fns';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import {AntivirusService} from 'store/services/antivirus';

import {ConditionValueGorm, EqualConditionGorm, FilterCondition, Filters} from '../../api/filters_gorm_pb';
import {DataActualsService} from '../services/actuals';
import {DataIngestionService} from '../services/dataIngestion';

import * as actionTypes from './actionTypes';

const getActualsListStart = () => ({
    type: actionTypes.GET_ACTUALS_LIST_START
});

const getActualsListSuccess = (actuals) => ({
    type: actionTypes.GET_ACTUALS_LIST_SUCCESS,
    actuals
});

const getActualsListFail = (error) => ({
    type: actionTypes.GET_ACTUALS_LIST_FAIL,
    error
});

export const updateActual = (assetID, actual) => () => {
    const request = new UpdateRequest();
    request.setId(actual.id);
    request.setAssetid(assetID);
    request.setCategory(actual.category);

    const company = new wrappers.StringValue();
    company.setValue(actual.company);
    request.setCompany(company);

    const date = new wrappers.StringValue();
    date.setValue(formatISO(actual.date, {representation: 'date'}));
    request.setDate(date);

    const subcategory = new wrappers.StringValue();
    subcategory.setValue(actual.subcategory);
    request.setSubcategory(subcategory);

    const credit = new wrappers.DoubleValue();
    credit.setValue(actual.credit);
    request.setCredit(credit);

    const debit = new wrappers.DoubleValue();
    debit.setValue(actual.debit);
    request.setDebit(debit);

    return new Promise((resolve, reject) => {
        DataActualsService.update(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};

export const createActual = (assetId, actual) => () => {
    const request = new CreateRequest();
    request.setAssetid(assetId);
    request.setCategory(actual.category);

    const company = new wrappers.StringValue();
    company.setValue(actual.company);
    request.setCompany(company);

    const date = new wrappers.StringValue();
    date.setValue(formatISO(actual.date, {representation: 'date'}));
    request.setDate(date);

    const subcategory = new wrappers.StringValue();
    subcategory.setValue(actual.subcategory);
    request.setSubcategory(subcategory);

    const credit = new wrappers.DoubleValue();
    credit.setValue(actual.credit);
    request.setCredit(credit);

    const debit = new wrappers.DoubleValue();
    debit.setValue(actual.debit);
    request.setDebit(debit);

    return new Promise((resolve, reject) => {
        DataActualsService.create(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};

export const deleteActual = (id) => () => {
    const request = new DeleteRequest();
    request.setId(id);

    return new Promise((resolve, reject) => {
        DataActualsService.delete(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};

export const getSubcategoriesList = (category, assetId) => () => {
    const request = new GetSubCategoriesRequest();
    request.setCategory(category);

    const filters = new Filters();
    const filterCondition = new FilterCondition();
    const equalCondition = new EqualConditionGorm();
    equalCondition.setField('AssetID');
    const conditionValue = new ConditionValueGorm();
    conditionValue.setStringvalue(assetId);

    equalCondition.setValue(conditionValue);
    filterCondition.setEqual(equalCondition);
    filters.setFilterconditionsList([filterCondition]);
    request.setFilters(filters);

    return new Promise((resolve, reject) => {
        DataActualsService.listSubCategories(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject().subcategoriesList);
            }
        });
    });
};

export const getActualsList = (assetId) => (dispatch) => {
    dispatch(getActualsListStart());
    const request = new ListActualsRequest();

    const filters = new Filters();
    const filterCondition = new FilterCondition();
    const equalCondition = new EqualConditionGorm();
    equalCondition.setField('AssetID');
    const conditionValue = new ConditionValueGorm();
    conditionValue.setStringvalue(assetId);

    equalCondition.setValue(conditionValue);
    filterCondition.setEqual(equalCondition);
    filters.setFilterconditionsList([filterCondition]);
    request.setFilters(filters);

    return new Promise((resolve, reject) => {
        DataActualsService.listActuals(request, metaData(), (err, response) => {
            if (err) {
                dispatch(getActualsListFail(err.message));
                reject(err.message);
            } else {
                dispatch(getActualsListSuccess(response.toObject().actualsList));
                resolve(response.toObject());
            }
        });
    });
};

export const uploadActuals = (path, assetId) => () => {
    const request = new IngestActualsRequest();
    request.setAssetid(assetId);
    request.setPath(path);

    return new Promise((resolve, reject) => {
        DataIngestionService.ingestActuals(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};

export const resetActuals = () => ({
    type: actionTypes.RESET_ACTUALS_STATE
});

export const runAntivirusScan = (files) => {
    const fileList = [];
    let infected = false;
    let error = null;
    files.forEach((file) => {
        const newFile = new ScanRequest.File();
        newFile.setPath(file.name);
        newFile.setTargetbucket('user-files-to-be-scanned-ad');
        newFile.setTargetpath(file.name);
        fileList.push(newFile);
    });

    const request = new ScanRequest();
    request.setFilesList(fileList);
    const stream = AntivirusService.scan(request, metaData());

    stream.on('data', (response) => {
        const {resultsList} = response.toObject();
        const infectedFiles = resultsList.filter((item) => item.infected);
        if (infectedFiles.length > 0) {
            infected = true;
        }
    });
    stream.on('error', (err) => {
        error = err;
    });

    return {
        infected,
        error
    };
};

export const runAntivirusScanCorrected = (files, bucket) => {
    return new Promise((resolve, reject) => {
        let infected = false;
        let error = null;
        let filesList = [];
        files.forEach((file) => {
            const newFile = new ScanRequest.File();
            newFile.setPath(file.name);
            newFile.setTargetbucket(bucket);
            newFile.setTargetpath(file.targetPath);
            filesList.push(newFile);
        });

        const request = new ScanRequest();
        request.setFilesList(filesList);
        const stream = AntivirusService.scan(request, metaData());

        stream.on('data', (response) => {
            const {resultsList} = response.toObject();
            const infectedFiles = resultsList.filter((item) => item.infected);
            if (infectedFiles.length > 0) {
                infected = true;
            }
            resolve({infected, error});
        });
        stream.on('error', (err) => {
            error = err;
            reject(err);
        });
        stream.on('end', () => {
            resolve({infected, error});
        });
    });
};
