import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signOut } from 'firebase/auth';
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  getMetadata,
  deleteObject,
  uploadBytes
} from 'firebase/storage';
import 'firebase/compat/firestore';
import { getEnv } from 'helpers/utils';

const domainName = window.location.hostname;

const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID
};

const testConfig = {
  apiKey: process.env.REACT_APP_TEST_API_KEY,
  authDomain: process.env.REACT_APP_TEST_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_TEST_DATABASE_URL,
  projectId: process.env.REACT_APP_TEST_PROJECT_ID,
  storageBucket: process.env.REACT_APP_TEST_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_TEST_MESSAGING_SENDER_ID
};

const preprodConfig = {
  apiKey: process.env.REACT_APP_PREPROD_API_KEY,
  authDomain: process.env.REACT_APP_PREPROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PREPROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PREPROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PREPROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PREPROD_MESSAGING_SENDER_ID
};

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID
};

const getEnviroment = () => {
  if (domainName.includes('localhost') || domainName.includes('dev')) {
    localStorage.setItem('env', 'dev');
    return devConfig;
  }

  if (domainName.includes('test') || domainName.includes('demo')) {
    localStorage.setItem('env', 'test');
    return testConfig;
  }

  if (domainName.includes('preprod') || domainName.includes('pre-prod')) {
    localStorage.setItem('env', 'preprod');
    return preprodConfig;
  }

  if (domainName.includes('prod') || domainName.includes('ad.recognyte.com')) {
    localStorage.setItem('env', 'prod');
    return prodConfig;
  }

  return devConfig;
};

// Initialize Firebase
const firebaseApp = initializeApp(getEnviroment());
const authService = getAuth(firebaseApp);
const storageService = getStorage(firebaseApp);
const rentrollStorage = getStorage(firebaseApp, `gs://rentrolls-${getEnv()}`);
const antivirusStorage = getStorage(firebaseApp, 'user-files-to-be-scanned-ad');
const antivirusCorrectedStorage = getStorage(firebaseApp, getEnv() === 'dev' ? 'user-files-to-be-scanned-ad' : `user-files-to-be-scanned-ad-${getEnv()}`);
const googleProvider = new GoogleAuthProvider();

export {
  firebaseApp,
  authService,
  storageService,
  ref,
  listAll,
  rentrollStorage,
  getDownloadURL,
  getMetadata,
  deleteObject,
  uploadBytes,
  onAuthStateChanged,
  googleProvider,
  signOut,
  antivirusStorage,
  antivirusCorrectedStorage
};
