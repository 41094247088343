import React from 'react';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SsidChartOutlinedIcon from '@mui/icons-material/SsidChartOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import useRouter from 'hooks/useRouter';
import {Link as RouterLink} from 'react-router-dom';
import {ListItemButton} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.spacing(2),
        marginLeft: theme.spacing(2),
        color: theme.palette.text.grey,
        fontFamily: theme.fonts.openSans,
        padding: theme.spacing(1)
    },
    details: {
        fontSize: 16,
        color: '#000',
        fontFamily: theme.fonts.openSans,
        padding: theme.spacing(1),
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.8'
        }
    },
    drawerPaper: {
        'overflow-y': 'auto',
        width: '200px',
        top: 'unset',
        backgroundColor: '#3379FF2B',
        '& .Mui-selected': {
            backgroundColor: '#FFF',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.3)'
            }
        }
    },
    light: {
        backgroundColor: '#555'
    },
    black: {
        color: theme.palette.common.black
    },
    paddingBottom: {
        paddingBottom: '65px !important'
    },
    itemIcon: {
        minWidth: 50,
        marginLeft: 12
    }
}));

const SideMenu = () => {
    const classes = useStyles();
    const router = useRouter();
    const {id, assetID} = router.query;
    const {tab} = router.match.params;

    const listItems = [
        { label: 'Site Plan', icon: DashboardOutlinedIcon, active: 'Details' },
        { label: 'Leases', icon: GroupIcon, active: 'Tenants' },
        { label: 'Opex Manager', icon: AnalyticsOutlinedIcon, active: 'OpexManager' },
        { label: 'Capex Manager', icon: AssessmentOutlinedIcon, active: 'CostManager' },
        { label: 'Turnover', icon: FolderOutlinedIcon, active: 'Turnover' },
        { label: 'Actuals', icon: FolderCopyOutlinedIcon, active: 'Actuals' },
        { label: 'Valuations', icon: SsidChartOutlinedIcon, active: 'Valuations' },
        { label: 'Documents', icon: AssignmentOutlinedIcon, active: 'Documents' },
        { label: 'Reports', icon: BarChartOutlinedIcon, active: 'Reports' },
    ];

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            classes={{
                paper: classes.drawerPaper
            }}
            style={{height: '100%', width: '200px'}}
        >
            <List classes={{
                selected: classes.selected
            }}>
                {listItems.map((item) => (
                    <ListItemButton
                        key={item.label}
                        className={classes.details}
                        selected={tab === item.active}
                        component={RouterLink}
                        to={`/Asset/${assetID || id}/${item.active}`}
                    >
                        <ListItemIcon className={classes.itemIcon}>
                            {<item.icon name="link_icon" className={classes.black} />}
                        </ListItemIcon>
                        <ListItemText>{item.label}</ListItemText>
                    </ListItemButton>
                ))}
            </List>
        </Drawer>
    );
};

export default SideMenu;
