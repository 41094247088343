import React from 'react';

import { Box, Button } from '@mui/material';
import { formatForBackEnd } from 'helpers/utils';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import ScheduleItem from './ScheduleItem';

const Schedule = ({ form, setForm, type }) => {
  const addItemSchedule = () => {
    setForm((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        schedule: {
          payments: [
            ...prevState[type].schedule.payments,
            {
              paymentID: uuidv4(),
              paymentAmount: 1000,
              paymentDate: formatForBackEnd(new Date('2022-01-01'))
            }
          ]
        }
      }
    }));
  };

  return (
    <Box>
      {form.supplier.schedule?.payments.map((item, index) => (
        <Box key={item.paymentID}>
          <ScheduleItem form={form} index={index} item={item} setForm={setForm} type={type} />
        </Box>
      ))}
      <Button onClick={addItemSchedule} color="primary" variant="outlined">
        + ADD ANOTHER PAYMENT
      </Button>
    </Box>
  );
};

export default Schedule;

Schedule.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  setForm: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};
