import TableContainer from "@mui/material/TableContainer";
import {Box, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import {AgGridReact} from "ag-grid-react";
import Grow from "@mui/material/Grow";
import React, {useMemo, useRef} from "react";

const AGGridTable = ({
                         deleteHandler,
                         colDefs,
                         data,
                         updateHandler,
                         classes,
                         buttons,
                         onRowClicked,
                         onCellClicked,
                         gridRef,
                         onGridReady,
                         rowHeight,
                         getRowStyle,
                         autoSizeStrategy = {
                             type: 'fitCellContents',
                         },
    rowGroupPanelShow,
    groupDisplayType
                     }) => {
    gridRef ??= useRef();

    const onBtDelete = () => {
        // get the first child of the
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (!selectedRows || selectedRows.length === 0) {
            console.log("No rows selected!");
            return;
        }

        deleteHandler(selectedRows, gridRef.current.api);
    };

    for (let i = 0; i < colDefs.length; i++) {
        if (colDefs[i].editable) {
            let originalFunction = colDefs[i].cellStyle
            colDefs[i].cellStyle = (params) => {
                if (!originalFunction) {
                    return {color: 'blue'}
                }

                let style = typeof originalFunction === 'function' ? originalFunction(params) : originalFunction
                if (style === null) {
                    style = {}
                }

                return {...style, color: 'blue'}
            }
        }
    }

    const dataTypeDefinitions = useMemo(() => {
        return {
            // override `date` to handle custom date format `dd/mm/yyyy`
            date: {
                baseDataType: 'date',
                extendsDataType: 'date',
                valueFormatter: params => {
                    if (params.value == null || params.value === '') {
                        return '';
                    }

                    const date = new Date(params.value);

                    return `${date.getDate()} ${date.toLocaleString('default', {month: 'short'})}, ${date.getFullYear()}`;
                },
            }
        };
    }, []);

    if (!buttons) {
        buttons = [];
    }
    if (deleteHandler) {
        buttons.push(<Button
            className={classes.ctaButton}
            startIcon={<Icon style={{color: '#5381EF'}}>delete</Icon>}
            onClick={onBtDelete}
        >
            Delete
        </Button>)
    }

    return (
        <Grow in>
            <TableContainer component={Paper} className={classes.paddingY}>
                {buttons && <Box sx={{display: 'flex', padding: '30px 30px 0 30px'}}>
                    {buttons}
                </Box>}
                <div
                    className="ag-theme-quartz" // applying the grid theme
                    style={{height: 560, margin: '30px'}} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        enableCharts={true}
                        onGridReady={onGridReady}
                        groupDisplayType={groupDisplayType}
                        ref={gridRef}
                        columnDefs={colDefs}
                        rowData={data}
                        enableRangeSelection={true}
                        statusBar={{
                            statusPanels: [
                                {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
                                {
                                    statusPanel: 'agAggregationComponent', statusPanelParams: {
                                        aggFuncs: ['avg', 'sum']
                                    }
                                },
                            ]
                        }}
                        autoSizeStrategy={autoSizeStrategy}
                        onCellValueChanged={(value) => {
                            if (value.rowPinned === 'top') return;
                            updateHandler(value, gridRef.current.api)
                        }}
                        undoRedoCellEditing={true}
                        undoRedoCellEditingLimit={5}
                        suppressRowClickSelection={true}
                        onCellClicked={onCellClicked}
                        rowSelection={"multiple"}
                        dataTypeDefinitions={dataTypeDefinitions}
                        onRowClicked={onRowClicked}
                        getRowStyle={getRowStyle}
                        processDataFromClipboard={params => {
                            const emptyLastRow = params.data[params.data.length - 1][0] === ''
                                && params.data[params.data.length - 1].length === 1;

                            if (emptyLastRow) {
                                params.data.splice(params.data.length - 1, 1);
                            }

                            const lastIndex = gridRef.current.api.getModel().rowsToDisplay.length - 1;
                            const focusedCell = gridRef.current.api.getFocusedCell();
                            const focusedIndex = focusedCell.rowIndex;

                            if (focusedIndex + params.data.length - 1 > lastIndex) {
                                const resultLastIndex = focusedIndex + (params.data.length - 1);
                                const addRowCount = resultLastIndex - lastIndex;
                                let rowsToAdd = [];
                                let addedRows = 0;
                                let currIndex = params.data.length - 1;
                                while (addedRows < addRowCount) {
                                    rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
                                    addedRows++;
                                    currIndex--;
                                }
                                rowsToAdd = rowsToAdd.reverse();
                                let newRowData = [];
                                rowsToAdd.map(r => {
                                    let row = {};
                                    let currColumn = focusedCell.column;
                                    r.map(i => {
                                        row[currColumn.colDef.field] = i;
                                        currColumn = gridRef.current.columnApi.getDisplayedColAfter(currColumn);
                                    });
                                    newRowData.push(row);
                                })
                                gridRef.current.api.updateRowData({add: newRowData});
                            }
                            return params.data;
                        }}
                        rowHeight={rowHeight}
                        rowGroupPanelShow={rowGroupPanelShow}
                    />
                </div>
            </TableContainer>
        </Grow>
    )
}

export default AGGridTable;
