import {ListDocumentsRequest, DeleteDocumentRequest, CreateDocumentRequest} from 'api/document_grpc_web_pb';
import {metaData} from 'config/config';
import {DocumentService} from '../services/document';
import {
    ConditionValueGorm,
    EqualConditionGorm,
    FilterCondition,
    Filters
} from 'api/filters_gorm_pb';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';

import * as actionTypes from './actionTypes';
import {formatISO} from "date-fns";

const getDocumentDataStart = () => ({
    type: actionTypes.GET_DOCUMENT_DATA_START
});

const getDocumentDataSuccess = (data) => ({
    type: actionTypes.GET_DOCUMENT_DATA_SUCCESS,
    data
});

const getDocumentDataFail = (error) => ({
    type: actionTypes.GET_DOCUMENT_DATA_FAIL,
    error
});

export const createDocument = (documents) => () => {
    const request = new CreateDocumentRequest();
    let documentsRequest = [];
    documents.map((document) => {
        let documentRequest = new CreateDocumentRequest.CreateDocumentMessage()
        if (document.assetID) {
            const assetIDWrapper = new wrappers.StringValue();
            assetIDWrapper.setValue(document.assetID);
            documentRequest.setAssetid(assetIDWrapper);
        }
        if (document.tenantID) {
            const tenantIDWrapper = new wrappers.StringValue();
            tenantIDWrapper.setValue(document.tenantID);
            documentRequest.setTenantid(tenantIDWrapper);
        }
        documentRequest.setDocumenttype(document.documentType);
        documentRequest.setFiletype(document.fileType);
        documentRequest.setPath(document.path);
        documentRequest.setName(document.name);
        documentRequest.setCategory(document.category);

        documentsRequest.push(documentRequest)
    });
    request.setDocumentsList(documentsRequest);

    return new Promise((resolve, reject) => {
        DocumentService.createDocument(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};

export const getDocumentData = (assetID, tenantID) => (dispatch) => {
    dispatch(getDocumentDataStart());
    const request = new ListDocumentsRequest();
    const filters = new Filters();
    let list = [];
    if (assetID) {
        const filterCondition = new FilterCondition();
        const equalCondition = new EqualConditionGorm();
        equalCondition.setField('AssetID');
        const conditionValue = new ConditionValueGorm();
        conditionValue.setStringvalue(assetID);
        equalCondition.setValue(conditionValue);
        filterCondition.setEqual(equalCondition);
        list.push(filterCondition);
    }
    if (tenantID) {
        const filterCondition = new FilterCondition();
        const equalCondition = new EqualConditionGorm();
        equalCondition.setField('TenantID');
        const conditionValue = new ConditionValueGorm();
        conditionValue.setStringvalue(tenantID);
        equalCondition.setValue(conditionValue);
        filterCondition.setEqual(equalCondition);
        list.push(filterCondition);
    }

    filters.setFilterconditionsList(list);
    request.setFilters(filters);

    return new Promise((resolve, reject) => {
        DocumentService.listDocuments(request, metaData(), (err, response) => {
            if (err) {
                dispatch(getDocumentDataFail(err.message));
                reject(err.message);
            } else {
                dispatch(getDocumentDataSuccess(response.toObject()));
                resolve(response.toObject());
            }
        });
    });
};

export const deleteDocument = (ids) => () => {
    const request = new DeleteDocumentRequest();
    request.setIdList(ids);

    return new Promise((resolve, reject) => {
        DocumentService.deleteDocument(request, metaData(), (err, response) => {
            if (err) {
                reject(err.message);
            } else {
                resolve(response.toObject());
            }
        });
    });
};
