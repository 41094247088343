import React, { useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import useStyles from './styles';
import { Grid2 } from '@mui/material';
import { formatZillions } from "../../helpers/utils";

const QuickKPIs = ({grossValue, annualizedIncome, GLA, Occupancy}) => {
  const classes = useStyles();

  return (
    <Grid2 container sx={{
      background: '#F7F8FD',
      borderRadius: '12px'
    }}>
      <Grid2 item size={12} sx={{borderBottom: '1px solid #4A445929'}}>
        <Typography variant="h6" sx={{padding: '20px'}}>
          Quick KPIs
        </Typography>
      </Grid2>
      <Grid2 item size={12}>
        <Box className={classes.task}>
          <Typography
            data-cy="vacant-units-text"
            variant="subtitle1"
            className={classes.fontLight}
          >
            Gross Asset Value
          </Typography>
          <Typography
            data-cy="vacant-units-count"
            variant="subtitle1"
            className={classes.fontLight}
          >
            €{formatZillions(grossValue, '', 2) }
          </Typography>
        </Box>
        <Box className={classes.task}>
          <Typography
            data-cy="lease-expires-text"
            variant="subtitle1"
            className={classes.fontLight}
          >
            Annualized Income
          </Typography>
          <Typography
            data-cy="lease-expires-count"
            variant="subtitle1"
            className={classes.fontLight}
          >
            €{formatZillions(annualizedIncome, '', 2) }
          </Typography>
        </Box>
        <Box className={classes.task}>
          <Typography
            data-cy="lease-break-text"
            variant="subtitle1"
            className={classes.fontLight}
          >
            GLA
          </Typography>
          <Typography
            data-cy="lease-break-count"
            variant="subtitle1"
            className={classes.fontLight}
          >
            {formatZillions(GLA, '', 2) } sqm
          </Typography>
        </Box>
        <Box className={classes.task}>
          <Typography data-cy="opex-text" variant="subtitle1" className={classes.fontLight}>
            Occupancy
          </Typography>
          <Typography data-cy="opex-count" variant="subtitle1" className={classes.fontLight}>
            {Occupancy}%
          </Typography>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default QuickKPIs;
