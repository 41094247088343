import React, { useEffect, useState } from 'react';

import { Box, TextField, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import DatePicker from 'components/DatePicker/KeyboardPicker';
import { closeModal } from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import { isValid } from 'helpers/utils';
import useUrlParams from 'hooks/useUrlParams';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';

const makeBundleId = (portfolioIds) => `PortfolioIds${portfolioIds}`;

const getForm = (tenant, details, isEdit, unitid) => ({
  companyname: isEdit ? tenant.companyname : '',
  tradingname: isEdit ? tenant.tradingname : '',
  id: isEdit ? tenant.id : null,
  industry: isEdit ? tenant.industry : '',
  unitidsList:
    isEdit && details.unitidsList !== undefined ? details.unitidsList : unitid ? [unitid] : [],
  tenantcontractid:
    isEdit && details.tenantcontractid !== undefined ? details.tenantcontractid : '',
  contractstartdate: isEdit ? new Date(tenant.startdate) : new Date(),
  contractenddate: isEdit ? new Date(tenant.enddate) : new Date(),
  headOfficeAddress: isEdit ? tenant.headOfficeAddress : '',
  notes: isEdit ? tenant.notes : '',
  mainContact: isEdit ? tenant.mainContact : '',
  position: isEdit ? tenant.position : '',
  email: isEdit ? tenant.email : '',
  phone: isEdit ? tenant.phone : ''
});

const TenantModal = ({ unitid, assetid }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.tenants.loading);
  const units = useSelector((state) => state.assets.unit.unitSchedule);
  const { isEdit } = useSelector((state) => state.modals.modalProps);
  const { portfolioIds } = useUrlParams();
  const { details } = useSelector((state) => state.leasingManager.details);
  const { data } = useSelector((state) => state.tenants.tenantDetails);
  const { tenants } = useSelector((state) => state.tenants);
  const [form, setForm] = useState(getForm(data, details, isEdit, unitid));
  const [nameError, setNameError] = useState(false);

  useEffect(() => {
    const bundleId = makeBundleId(portfolioIds);
    dispatch(actions.getTenants(portfolioIds, bundleId, [], true));
    dispatch(actions.getUnitSchedule(assetid));
  }, [portfolioIds]);

  const updateTenant = () =>
    dispatch(actions.updateTenant(form))
      .then(() => {
        dispatch(actions.getTenantDetails(form.id));
        dispatch(actions.getTenantHeader(form.id));
        toast.success('Tenant Updated');
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        dispatch(actions.getTenantsByAssetId(assetid));
        dispatch(closeModal());
      });

  const createTenant = () =>
    dispatch(actions.createTenant(form, assetid))
      .then(() => {
        dispatch(actions.getTenants(portfolioIds, makeBundleId(portfolioIds), [], true));
        toast.success('Tenant Added');
        setForm(getForm());
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        dispatch(actions.getTenantsByAssetId(assetid));
        dispatch(closeModal());
      });

  const handleSave = () => {
    if (
      !isValid(form.companyname) ||
      !isValid(form.tradingname) ||
      !isValid(form.industry) ||
      nameError
    ) {
      return toast.error('Please fill in the form!');
    }

    return isEdit ? updateTenant() : createTenant();
  };

  const handleChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'tradingname') {
      if (tenants.tenants.find((element) => element.tradingname === value)) {
        setNameError(true);
      } else {
        setNameError(false);
      }
    }
  };

  return (
    <ModalWrapper
      size="xl"
      header={isEdit ? 'Edit Tenant' : 'Create Tenant'}
      handleSave={handleSave}
      disabled={isEqual(getForm(data, details, isEdit), form)}
      loading={loading}
      label="Save"
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Autocomplete
          id="units"
          options={units}
          getOptionLabel={(option) => option.unitname}
          className={classes.autocomplete}
          autoComplete
          autoHighlight
          clearOnEscape
          multiple
          value={units?.filter((unit) => form.unitidsList.indexOf(unit.unitid) > -1)}
          onChange={(_, value) => {
            handleChange(
              'unitidsList',
              value.map((val) => val.unitid)
            );
          }}
          renderInput={(params) => <TextField {...params} label="Units" variant="outlined" />}
        />
        <Box mt={2}>
          <DatePicker
            format="DD/MM/YYYY"
            selectedDate={form.contractstartdate}
            setSelectedDate={(e) => handleChange('contractstartdate', e)}
            label="Term Start Date"
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
          />
        </Box>

        <Box mt={3}>
          <DatePicker
            format="DD/MM/YYYY"
            selectedDate={form.contractenddate}
            minDate={form.contractstartdate}
            setSelectedDate={(e) => handleChange('contractenddate', e)}
            label="Term End Date"
            minErrorText="Date cannot be before term start date"
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
          />
        </Box>
        <Autocomplete
          id="company"
          options={tenants.tenants}
          getOptionLabel={(tenant) => (typeof tenant === 'object' ? tenant.companyname : tenant)}
          className={classes.autocomplete}
          autoComplete
          freeSolo
          autoHighlight
          clearOnEscape
          label="Company Name"
          required
          value={tenants?.tenants?.find((tenant) => tenant.id === form.id) || form.companyname}
          disabled={loading}
          onInputChange={(_, value) => handleChange('companyname', value)}
          onChange={(_, value) => {
            if (value === null) {
              return;
            }

            handleChange('companyname', value.companyname);
            handleChange('tradingname', value.tradingname);
            handleChange('industry', value.industry);
            handleChange('contractstartdate', new Date(value.contractstartdate));
            handleChange('contractenddate', new Date(value.contractenddate));
            handleChange('notes', value.notes);
            handleChange('phone', value.phone);
            handleChange('position', value.position);
            handleChange('mainContact', value.mainContact);
            handleChange('headOfficeAddress', value.headOfficeAddress);
            handleChange('email', value.email);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Company name" variant="outlined" />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.companyname}
              </li>
            );
          }}
        />
        <TextField
          fullWidth
          required
          label="Tenant Contract Reference"
          variant="outlined"
          className={classes.textField}
          value={form.tradingname}
          disabled={loading}
          error={nameError}
          helperText={'Tenant Contract Reference should be unique'}
          onChange={(e) => handleChange('tradingname', e.target.value)}
          id="Tenant Contract Reference"
          slotProps={{
            input: {
              classes: {
                input: classes.input
              }
            }
          }}
        />
        <TextField
          fullWidth
          required
          label="Industry"
          variant="outlined"
          className={classes.textField}
          value={form.industry}
          onChange={(e) => handleChange('industry', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.selectInput
              }
            }
          }}
        />
        <Typography variant="h5">Tenant Details</Typography>
        <TextField
          fullWidth
          label="Head Office Address"
          variant="outlined"
          className={classes.textField}
          value={form.headOfficeAddress}
          onChange={(e) => handleChange('headOfficeAddress', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.selectInput
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Main Contact"
          variant="outlined"
          className={classes.textField}
          value={form.mainContact}
          onChange={(e) => handleChange('mainContact', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.selectInput
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Position"
          variant="outlined"
          className={classes.textField}
          value={form.position}
          onChange={(e) => handleChange('position', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.selectInput
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          className={classes.textField}
          value={form.email}
          onChange={(e) => handleChange('email', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.selectInput
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Phone"
          variant="outlined"
          className={classes.textField}
          value={form.phone}
          onChange={(e) => handleChange('phone', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.selectInput
              }
            }
          }}
        />
        <TextField
          fullWidth
          label="Notes"
          variant="outlined"
          className={classes.textField}
          value={form.notes}
          onChange={(e) => handleChange('notes', e.target.value)}
          slotProps={{
            input: {
              classes: {
                input: classes.selectInput
              }
            }
          }}
        />
      </Box>
    </ModalWrapper>
  );
};

export default TenantModal;
