import React, { useEffect } from "react";

import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import DatePicker from 'components/DatePicker/FormikPicker';
import ModalWrapper from 'components/Modal/FormikModalWrapper';
import { closeModal } from 'components/Modal/modalReducer';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';
import * as Yup from 'yup';
import { Grid2, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const getForm = (form) => ({
  valuation: '',
  date: new Date(),
  valuer: '',
  marketvalue: '',
  liquidationvalue: '',
  summary: '',
  assetid: '',
  id: '',
  dcfMethodValue: '',
  comparativeMethodValue: '',
  insuranceMethodValue: '',
  currentRent: '',
  erv: '',
  discountRate: '',
  exitYield: '',
  ...form
});

const validationSchema = Yup.object({
  valuation: Yup.string().required('Valuation is Required!'),
  date: Yup.date().required('Valuation Date is Required!'),
  valuer: Yup.string(),
  marketvalue: Yup.string().required('Market Value is Required!'),
  liquidationvalue: Yup.string(),
  summary: Yup.string(),
  assetid: Yup.string(),
  id: Yup.string(),
  dcfMethodValue: Yup.string(),
  comparativeMethodValue: Yup.string(),
  insuranceMethodValue: Yup.string(),
  currentRent: Yup.string(),
  erv: Yup.string(),
  discountRate: Yup.string(),
  exitYield: Yup.string()
});

const AddValuation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { valuations, fetch } = useSelector((state) => state.modals.modalProps);
  const loading = useSelector((state) => state.valuations.loading);
  const { valuers, loaded: valuersLoaded } = useSelector((state) => state.valuations.valuers);
  const { data: asset } = useSelector((state) => state.assets.assetDetails);

  useEffect(() => {
    dispatch(actions.searchValuer(""))
  }, []);

  const handleSave = (form) => {
    dispatch(actions.createValuation(form, asset.id))
      .then(() => {
        if (form.id) {
          dispatch(actions.getValuationsDetails(form.id));
          fetch();
        }
        dispatch(actions.getValuations(asset.id));
      })
      .then(() => {
        toast.success('Success');
        dispatch(closeModal());
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    (<ModalWrapper size="md" header={valuations?.id ? 'Edit Valuation' : 'New Valuation'} xl>
      <Formik
        initialValues={getForm(valuations)}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSave(values);
        }}
      >
        {({ values, isSubmitting, dirty, isValid, touched, errors, submitForm, handleChange }) => (
          <>
            <DialogContent>
              <TextField
                fullWidth
                label="Valuation name"
                variant="outlined"
                disabled={loading}
                required
                className={classes.textFieldXL}
                placeholder="Valuation 1"
                name="valuation"
                id="valuation"
                value={values.valuation}
                onChange={handleChange}
                error={touched.valuation && Boolean(errors.valuation)}
                helperText={touched.valuation && errors.valuation}
                slotProps={{
                  input: {
                    classes: {
                      input: classes.input
                    }
                  }
                }}
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <DatePicker
                  selectedDate={values.date ? new Date(values.date) : new Date()}
                  onChange={handleChange}
                  format="DD/MM/YYYY" label="Date of Valuation" name="date" id="date" />
                <Autocomplete
                  id="valuer"
                  options={[...new Set(valuers)] || []}
                  getOptionLabel={(option) => option}
                  className={classes.autocomplete}
                  autoComplete
                  freeSolo
                  autoHighlight
                  clearOnEscape
                  required
                  disabled={!valuersLoaded}
                  value={values.valuer}
                  onChange={(_, value) => {
                    if (value === null) {
                      return;
                    }

                    values.valuer = value;
                  }}
                  onInputChange={(_, value) => {
                    values.valuer = value;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Valuer" variant="outlined" />
                  )}
                />
              </Box>

              <Typography variant="h5">Value Types</Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <TextField
                  fullWidth
                  label="FMV (€)"
                  variant="outlined"
                  className={classes.textField}
                  type="number"
                  disabled={loading}
                  name="marketvalue"
                  required
                  id="marketvalue"
                  value={values.marketvalue}
                  onChange={handleChange}
                  error={touched.marketvalue && Boolean(errors.marketvalue)}
                  helperText={touched.marketvalue && errors.marketvalue}
                  style={{ width: '48%' }}
                  slotProps={{
                    input: {
                      classes: {
                        input: classes.input
                      }
                    }
                  }}
                />
                <TextField
                  fullWidth
                  label="Liquidation (€)"
                  variant="outlined"
                  className={classes.textField}
                  type="number"
                  disabled={loading}
                  name="liquidationvalue"
                  id="liquidationvalue"
                  value={values.liquidationvalue}
                  onChange={handleChange}
                  error={touched.liquidationvalue && Boolean(errors.liquidationvalue)}
                  helperText={touched.liquidationvalue && errors.liquidationvalue}
                  style={{ width: '48%' }}
                  slotProps={{
                    input: {
                      classes: {
                        input: classes.input
                      }
                    }
                  }}
                />
              </Box>

              <Grid2 container spacing={2}>
                <Grid2 size={{lg:4}}>
                  <TextField
                    fullWidth
                    label="DCF Method Value (€)"
                    variant="outlined"
                    className={classes.textField}
                    type="number"
                    disabled={loading}
                    name="dcfMethodValue"
                    id="dcfMethodValue"
                    value={values.dcfMethodValue}
                    onChange={handleChange}
                    error={touched.dcfMethodValue && Boolean(errors.dcfMethodValue)}
                    helperText={touched.dcfMethodValue && errors.dcfMethodValue}
                    slotProps={{
                      input: {
                        classes: {
                          input: classes.input
                        }
                      }
                    }}
                  />
                </Grid2>
                <Grid2 size={{lg:4}}>
                  <TextField
                    fullWidth
                    label="Comparative Method Value (€)"
                    variant="outlined"
                    className={classes.textField}
                    type="number"
                    disabled={loading}
                    name="comparativeMethodValue"
                    id="comparativeMethodValue"
                    value={values.comparativeMethodValue}
                    onChange={handleChange}
                    error={touched.comparativeMethodValue && Boolean(errors.comparativeMethodValue)}
                    helperText={touched.comparativeMethodValue && errors.comparativeMethodValue}
                    slotProps={{
                      input: {
                        classes: {
                          input: classes.input
                        }
                      }
                    }}
                  />
                </Grid2>
                <Grid2 size={{lg:4}}>
                  <TextField
                    fullWidth
                    label="Insurance Value (€)"
                    variant="outlined"
                    className={classes.textField}
                    type="number"
                    disabled={loading}
                    name="insuranceMethodValue"
                    id="insuranceMethodValue"
                    value={values.insuranceMethodValue}
                    onChange={handleChange}
                    error={touched.insuranceMethodValue && Boolean(errors.insuranceMethodValue)}
                    helperText={touched.insuranceMethodValue && errors.insuranceMethodValue}
                    slotProps={{
                      input: {
                        classes: {
                          input: classes.input
                        }
                      }
                    }}
                  />
                </Grid2>
              </Grid2>

              <Typography variant="h5">Other KPIs</Typography>

              <Grid2 container spacing={2}>
                <Grid2 size={{lg:4}}>
                  <TextField
                    fullWidth
                    label="Current Rent (€)"
                    variant="outlined"
                    className={classes.textField}
                    type="number"
                    disabled={loading}
                    name="currentRent"
                    id="currentRent"
                    value={values.currentRent}
                    onChange={handleChange}
                    error={touched.currentRent && Boolean(errors.currentRent)}
                    helperText={touched.currentRent && errors.currentRent}
                    slotProps={{
                      input: {
                        classes: {
                          input: classes.input
                        }
                      }
                    }}
                  />
                </Grid2>
                <Grid2 size={{lg:4}}>
                  <TextField
                    fullWidth
                    label="ERV (€)"
                    variant="outlined"
                    className={classes.textField}
                    type="number"
                    disabled={loading}
                    name="erv"
                    id="erv"
                    value={values.erv}
                    onChange={handleChange}
                    error={touched.erv && Boolean(errors.erv)}
                    helperText={touched.erv && errors.erv}
                    slotProps={{
                      input: {
                        classes: {
                          input: classes.input
                        }
                      }
                    }}
                  />
                </Grid2>
                <Grid2 size={{lg:4}}>
                  <TextField
                    fullWidth
                    label="Discount Rate (%)"
                    variant="outlined"
                    className={classes.textField}
                    type="number"
                    disabled={loading}
                    name="discountRate"
                    id="discountRate"
                    value={values.discountRate}
                    onChange={handleChange}
                    error={touched.discountRate && Boolean(errors.discountRate)}
                    helperText={touched.discountRate && errors.discountRate}
                    slotProps={{
                      input: {
                        classes: {
                          input: classes.input
                        }
                      }
                    }}
                  />
                </Grid2>
                <Grid2 size={{lg:4}}>
                  <TextField
                    fullWidth
                    label="Exit Yield (%)"
                    variant="outlined"
                    className={classes.textField}
                    type="number"
                    disabled={loading}
                    name="exitYield"
                    id="exitYield"
                    value={values.exitYield}
                    onChange={handleChange}
                    error={touched.exitYield && Boolean(errors.exitYield)}
                    helperText={touched.exitYield && errors.exitYield}
                    slotProps={{
                      input: {
                        classes: {
                          input: classes.input
                        }
                      }
                    }}
                  />
                </Grid2>
              </Grid2>

              <TextField
                fullWidth
                label="Report Summary"
                multiline
                variant="outlined"
                className={classes.textFieldXL}
                disabled={loading}
                name="summary"
                id="summary"
                rows={5}
                value={values.summary}
                onChange={handleChange}
                error={touched.summary && Boolean(errors.summary)}
                helperText={touched.summary && errors.summary}
                placeholder="Enter your report"
                slotProps={{
                  input: {
                    classes: {
                      input: classes.input
                    }
                  }
                }}
              />
            </DialogContent>
            <DialogActions>
              <Box p={2} display="flex">
                <CancelButton onClick={() => dispatch(closeModal())} />
                <SaveButton
                  type="submit"
                  loading={loading}
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={submitForm}
                  data-testid="valuations-save"
                />
              </Box>
            </DialogActions>
          </>
        )}
      </Formik>
    </ModalWrapper>)
  );
};

export default AddValuation;
